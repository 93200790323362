// import logo from './logo.svg'
import {ThemeProvider} from '@mui/material/styles'
import {BrowserRouter} from 'react-router-dom'
import theme from './theme/themeConfig'
import './App.css'
import RoutesApp from './pages/Routes'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <RoutesApp />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
