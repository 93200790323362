import React from 'react'

const SVGEnergiaRecuperada = (props) => {
  return (
    <div>
      <svg
        width='90'
        height='90'
        viewBox='0 0 80 82.27'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='none'
          stroke='#2a8bca'
          strokeMiterlimit='10'
          strokeWidth='4.5px'
          d='M22.64,25.48c3.61-2.85,13.3-1.9,9.38,9.88-1.25,3.75-4,6.51-5.84,9.9C23.05,51,24,59.07,28.39,63.69'
        />
        <path
          fill='none'
          stroke='#2a8bca'
          strokeMiterlimit='10'
          strokeWidth='4.5px'
          d='M35.94,17.22c3.61-.21,13.65,2.07,9.84,14.34-1.18,3.8-4.86,6-7,9.32-3.9,6-3.86,18.3.57,23.85'
        />
        <path
          fill='none'
          stroke='#2a8bca'
          strokeMiterlimit='10'
          strokeWidth='4.5px'
          d='M52.92,26.44c7.62,1.43,9.53,10,.34,15.45a13.72,13.72,0,0,0-5.55,6c-2.12,4.63-.86,11.85,5.1,15.85'
        />
        <path
          fill='none'
          stroke='#2a8bca'
          strokeMiterlimit='10'
          strokeWidth='4.5px'
          d='M20.77,59.69c-3.59.41-9.07,2.16-9.3,4.84-.16,1.92-2.4,5.27,27.88,5.25,22.86,0,25.7-4.2,25.72-6.17s-7.62-4-10.48-3.92'
        />
      </svg>
    </div>
  )
}

export default SVGEnergiaRecuperada
