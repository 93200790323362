import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'

import {Box} from '@mui/material'

import SignInView from '../pages/SignIn/View/SignInView'
import TotalesAcumuladosView from '../pages/TotalesAcumulados/View/TotalesAcumuladosView'
import FlujoPurgasView from '../pages/FlujoPurgas/View/FlujoPurgasView'
import EnergiaRecuperadaView from '../pages/EnergiaRecuperada/View/EnergiaRecuperadaView'
import NotFoundView from '../pages/NotFound/View/NotFoundView'

const classes = {
  root: {
    backgroundColor: 'background.default',
  },
}

const RoutesApp = () => {
  return (
    <Box className={classes.root}>
      <Routes>
        <Route exact path='/' element={<Navigate to='/view/sign-in' />} />
        <Route exact path='/view/sign-in' element={<SignInView />} />
        <Route exact path='/view/totals' element={<TotalesAcumuladosView />} />
        <Route exact path='/view/flows' element={<FlujoPurgasView />} />
        <Route exact path='/view/energy-recovery' element={<EnergiaRecuperadaView />} />
        <Route exact path='*' element={<NotFoundView />} />
      </Routes>
    </Box>
  )
}

export default RoutesApp
