import React from 'react'

const SVGFlujo2 = (props) => {
  return (
    <div>
      <svg
        width='100'
        height='100'
        viewBox='0 0 116.59 116'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='none'
          stroke='#226fb7'
          strokeMiterlimit='10'
          strokeWidth='5.66px'
          d='M31.18,62.89c7.44-8.62,13-16.94,28.93-4.44,6.33,5,16.82,7.69,25.3-.72'
        />
        <path
          fill='none'
          stroke='#226fb7'
          strokeMiterlimit='10'
          strokeWidth='5.66px'
          d='M31.18,79.43c7.44-8.62,13-16.94,28.93-4.44,6.33,5,16.82,7.69,25.3-.72'
        />
        <path
          fill='none'
          stroke='#226fb7'
          strokeMiterlimit='10'
          strokeWidth='5.66px'
          d='M31.18,47.13c7.44-8.62,13-16.93,28.93-4.44,6.33,5,16.82,7.69,25.3-.72'
        />
      </svg>
    </div>
  )
}

export default SVGFlujo2
