import React from 'react'

const SVGGas = (props) => {
  return (
    <div>
      <svg
        width='89.375px'
        height='130px'
        viewBox='-18 -25 89.375 130'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          className='cls-1'
          transform='matrix(1.0000000000000002, 0, 0, 1.0000000000000002, -30.783178788172876, -18.93291501600913)'
        >
          <g id='Capa_1' data-name='Capa 1'>
            <path
              className='cls-3'
              d='M48.11,23.66c4.59,1.69,3.69,8.1,3.07,12.94s-3.5,9.1-6.62,12.86S38,56.76,35.7,61.1c-4.17,8.06-3.16,18.75,3.07,25.34s15.44,8.44,22,2.38c-5.45-.31-8.61-3.82-11.48-8.46s-6.39-12-4.23-17C48.85,54.62,62.73,50.48,63,41c.15-4.54-2.4-8.86-5.85-11.81S52.62,23.58,48.11,23.66Z'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-4'
              d='M44.84,22.79c4.74,1.75,7.09,7.44,6.45,12.45s-3.61,9.39-6.84,13.28-6.82,7.54-9.14,12C31,68.86,32,79.9,38.48,86.71s18.07,8.28,24.89,2A17.59,17.59,0,0,1,48.19,64.2c3.87-9,15-14.63,15.35-24.4.15-4.69-2.48-9.14-6-12.2A19,19,0,0,0,44.84,22.79Z'
              fill={'rgb(34, 111, 183)'}
            />
            <path
              className='cls-5'
              d='M68.48,41c-.19,4.56-3,8.53-5.93,11.9s-6.27,6.6-8,10.8c-2.91,7.21.24,16.46,6.87,20.17s15.79,1.36,20-5.12,2.84-16.2-3-21.18a27.62,27.62,0,0,1-.22,11.06c-1,3.58-3.29,6.92-6.64,8.23s-7.66.06-9.29-3.25c-1.55-3.16-.36-7.09,1.64-10s4.74-5.12,6.8-8,3.37-6.69,2-9.93C71.71,43.36,69.26,41.12,68.48,41Z'
              fill={'rgb(65, 157, 214)'}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default SVGGas
