import React, {useState, useEffect} from 'react'
import {Grid, Typography, Divider} from '@mui/material'
import {datetime} from '../../util/datetime'

const classes = {
  colorControlBottom: {
    color: 'dedicated.c15',
  },
  dividerDatetime: {
    backgroundColor: 'dedicated.c15',
    height: '1px',
    width: '60px',
  },
}

const ClockDatetime1 = (props) => {
  const [constructorHasRun, setConstructorHasRun] = useState(false)
  const [timeValue, setTimeValue] = useState('00:00')
  const [dateValue, setDateValue] = useState('00-00-0000')

  const refreshClock = () => {
    let data = datetime()
    setTimeValue(data.HHmm)
    setDateValue(data.ddMMyyyy)
  }

  useEffect(() => {
    const timerId = setInterval(refreshClock, 10000)
    const cleanup = () => {
      clearInterval(timerId)
    }
    return cleanup
  }, [])

  const constructor = () => {
    if (constructorHasRun) return
    // ------------
    refreshClock()
    // ------------
    setConstructorHasRun(true)
  }

  constructor()

  return (
    <Grid container direction={'row'} justifyContent={'center'} alignItems={'center'}>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={classes.colorControlBottom}
      >
        <Typography variant={'h6'}>{timeValue}</Typography>
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={classes.colorControlBottom}
      >
        <Divider sx={classes.dividerDatetime} />
      </Grid>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={classes.colorControlBottom}
      >
        <Typography variant={'caption'}>{dateValue}</Typography>
      </Grid>
    </Grid>
  )
}

export default ClockDatetime1
