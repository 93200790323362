import React, {useState, useEffect} from 'react'
import {Box, Grid, Typography, Tooltip} from '@mui/material'
import {formatDatetimeShort} from '../../util/datetime'

import {scl} from '../../util/scale'
import {io} from 'socket.io-client'
import {serverDeviceControl} from '../../servers'
import {dataSave, dataLastSaved} from '../../services/devices/data'

const socket = io(serverDeviceControl)

const GaugeFixed1 = (props) => {
  const backgroundImg = props.backgroundImg
  const centerImg = props.centerImg
  const minRange = props.minRange
  const maxRange = props.maxRange
  const title = props.title
  const unit = props.unit
  const event = props.event
  const alignVertival = props.alignVertival
  const variable = props.variable

  const [constructorHasRun, setConstructorHasRun] = useState(false)

  const [value, setValue] = useState(0)
  const [valueAngle, setValueAngle] = useState('0')
  const [valueTime, setValueTime] = useState(0)

  const angleChange = (value) => {
    let m1 = 90 / 50
    let y1 = m1 * value - m1 * 0 - 90
    let m2 = 90 / 50
    let y2 = m2 * value - m2 * 50
    let sclGrade = ''

    let numValue = Number(value)

    if (numValue <= 50) {
      sclGrade = `${y1}`
    } else {
      sclGrade = `${y2}`
    }
    setValueAngle(`${sclGrade}`)
  }

  useEffect(() => {
    socket.on(event, (data) => {
      if (data !== undefined && data !== null) {
        dataSave(data)
        let dataEvent = data.value.find((e) => e.variableId === variable)
        if (dataEvent !== undefined && dataEvent !== null) {
          if (dataEvent.type === 'float') {
            let dataValue = parseFloat(dataEvent.value)
            let sclValue = scl(parseInt(dataValue), minRange, maxRange, 0, 100, true)
            angleChange(sclValue)
            setValue(dataValue.toLocaleString('es-CL', {minimumFractionDigits: 1}))
            let valueDataTime = formatDatetimeShort(data.timestamp)
            setValueTime(valueDataTime.datetime)
          }
        }
      }
    })
  }, [event, minRange, maxRange, variable])

  const constructor = () => {
    if (constructorHasRun) return
    // ------------
    let getLastSaved = dataLastSaved(variable)
    if (getLastSaved !== null && getLastSaved !== undefined) {
      let valueSaved = parseFloat(getLastSaved.data.value)
      setValue(valueSaved.toLocaleString('es-CL', {minimumFractionDigits: 1}))
      let valueDataTime = formatDatetimeShort(getLastSaved.timestamp)
      setValueTime(valueDataTime.datetime)
    }
    // ------------
    setConstructorHasRun(true)
  }

  constructor()

  return (
    <Box
      sx={{
        position: 'relative',
        width: '200px',
        ...(alignVertival
          ? {
              height: '200px',
            }
          : {
              height: '140px',
            }),
      }}
    >
      {/* Background Img-Gauge */}
      <Box>
        <img
          alt=''
          width='255px'
          height='140px'
          src={backgroundImg}
          style={{
            position: 'absolute',
            top: '-20px',
            left: '-32px',
          }}
        />
      </Box>
      {/* Needle */}
      <Box
        sx={{
          backgroundColor: 'dedicated.c14',
          width: '3px',
          height: '100px',
          borderRadius: '3px',
          transform: `rotate(${valueAngle}deg)`,
          transformOrigin: 'center bottom',
          position: 'absolute',
          transitionDuration: '1s',
          left: '50%',
        }}
      ></Box>
      {/* Icon - Center */}
      <Box
        sx={{
          backgroundColor: 'dedicated.c15',
          width: '100px',
          height: '100px',
          borderRadius: '50px 50px 50px 50px',
          position: 'absolute',
          top: '40px',
          left: '50px',
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <img alt='' width='100px' height='100px' src={centerImg} />
      </Box>
      {/* Min Range */}
      <Grid
        container
        sx={{
          width: '40px',
          height: '20px',
          position: 'absolute',
          top: '105px',
          left: '0px',
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
        }}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item>{minRange}</Grid>
      </Grid>
      {/* Max Range */}
      <Grid
        container
        sx={{
          width: '40px',
          height: '20px',
          position: 'absolute',
          top: '105px',
          left: '160px',
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
        }}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item>{maxRange}</Grid>
      </Grid>
      {/* Title */}
      <Grid
        container
        sx={{
          width: '200px',
          height: '20px',
          position: 'absolute',
          ...(alignVertival
            ? {
                top: '135px',
                left: '0px',
              }
            : {
                top: '25px',
                left: '165px',
              }),
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
        }}
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid
          item
          sx={{
            ...(alignVertival
              ? {
                  textAlign: 'center',
                }
              : {textAlign: 'right'}),
          }}
        >
          {title}
        </Grid>
      </Grid>
      {/* Display Numeric */}
      <Tooltip title={`Último registro: ${valueTime}`}>
        <Grid
          container
          sx={{
            width: '200px',
            height: '35px',
            position: 'absolute',
            ...(alignVertival
              ? {
                  top: '180px',
                  left: '0px',
                }
              : {
                  top: '70px',
                  left: '190px',
                }),
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
          }}
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            sx={{
              backgroundColor: 'dedicated.c16',
              width: '150px',
              height: '35px',
              borderRadius: '5px',
              paddingRight: '10px',
              boxShadow: '1px 1px 1px rgba(68, 68, 68, 0.6)',
            }}
          >
            <Grid item>
              <Typography
                variant='h6'
                sx={{
                  fontFamily: 'Arial',
                }}
              >
                {<strong>{value}</strong>}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1' sx={{marginLeft: '5px'}}>
                {<strong>{unit}</strong>}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Tooltip>
    </Box>
  )
}

export default GaugeFixed1
