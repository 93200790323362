import axios from 'axios'
import {serverDeviceLog} from '../../../servers'

export const findGlobalData = async (datetimeStart, datetimeEnd, collection) => {
  try {
    let postDetail = {
      datetimeStart: datetimeStart,
      datetimeEnd: datetimeEnd,
      collection: collection,
    }
    let response = await axios({
      method: 'post',
      url: `${serverDeviceLog}/api/device/data/gl/find`,
      data: postDetail,
    })
    if (response !== null && response !== undefined) {
      return response.data
    } else {
      return {check: false, code: 400, detail: 'not data'}
    }
  } catch (error) {
    return {check: false, code: 400, detail: 'error'}
  }
}
