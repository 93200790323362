import React, {useState} from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Popover,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
  TextField,
  CircularProgress,
} from '@mui/material'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import {CloudDownload as CloudDownloadIcon} from '@mui/icons-material'

import * as Excel from 'exceljs'
import {saveAs} from 'file-saver'

import {findGlobalData} from './download-button-1.services'
import useWidth from '../../../util/useWidth'
import {datetime, rangeDatetime, formatDatetime} from '../../../util/datetime'

const DownloadButton1 = (props) => {
  const width = useWidth()

  const [constructorHasRun, setConstructorHasRun] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [isloading, setIsloading] = useState(true)
  const [filter, setFilter] = useState('today')
  const [selectedDateStart, setSelectedDateStart] = useState(datetime().nowUTC)
  const [selectedDateEnd, setSelectedDateEnd] = useState(datetime().nowUTC)

  const setDataRows = (data) => {
    let rowsBase = []
    if (data.length > 0) {
      data.forEach((e, i) => {
        let eTimestamp = formatDatetime(e.timestamp)
        rowsBase.push({
          created_at: eTimestamp.datetime,
          calorMakeupInstantaneo: parseFloat(e.data[0].value),
          calorMakeupAcc: parseFloat(e.data[1].value),
          temperaturaSalida: parseFloat(e.data[2].value),
          temperaturaEntrada: parseFloat(e.data[3].value),
          flujoInstantaneo: parseFloat(e.data[4].value),
          flujoAcumulado: parseFloat(e.data[5].value),
          vaporFlashInstantaneo: parseFloat(e.data[6].value),
          vaporFlashAcumulado: parseFloat(e.data[7].value),
          calorTotalInstantaneo: parseFloat(e.data[8].value),
          calorTotalAcumulado: parseFloat(e.data[9].value),
          ahorroGasIntantaneo: parseFloat(e.data[10].value),
          ahorroGasAcumulado: parseFloat(e.data[11].value),
          reduccionCO2: parseFloat(e.data[12].value),
        })
      })
    }
    exportXLSX(rowsBase)
  }

  const exportXLSX = async (data) => {
    let workbook = new Excel.Workbook()
    let worksheet = workbook.addWorksheet('Registros')

    worksheet.columns = [
      {header: 'Fecha/Hora', key: 'created_at', width: 25},
      {header: 'CalorMakeupInstantaneo', key: 'calorMakeupInstantaneo', width: 25},
      {header: 'CalorMakeupAcc', key: 'calorMakeupAcc', width: 25},
      {header: 'TemperaturaSalida', key: 'temperaturaSalida', width: 25},
      {header: 'TemperaturaEntrada', key: 'temperaturaEntrada', width: 25},
      {header: 'TlujoInstantaneo', key: 'flujoInstantaneo', width: 25},
      {header: 'FlujoAcumulado', key: 'flujoAcumulado', width: 25},
      {header: 'VaporFlashInstantaneo', key: 'vaporFlashInstantaneo', width: 25},
      {header: 'VaporFlashAcumulado', key: 'vaporFlashAcumulado', width: 25},
      {header: 'CalorTotalInstantaneo', key: 'calorTotalInstantaneo', width: 25},
      {header: 'calorTotalAcumulado', key: 'calorTotalAcumulado', width: 25},
      {header: 'AhorroGasIntantaneo', key: 'ahorroGasIntantaneo', width: 25},
      {header: 'AhorroGasAcumulado', key: 'ahorroGasAcumulado', width: 25},
      {header: 'ReduccionCO2', key: 'reduccionCO2', width: 25},
    ]

    data.forEach((e) => {
      worksheet.addRow({
        created_at: e.created_at,
        calorMakeupInstantaneo: e.calorMakeupInstantaneo,
        calorMakeupAcc: e.calorMakeupAcc,
        temperaturaSalida: e.temperaturaSalida,
        temperaturaEntrada: e.temperaturaEntrada,
        flujoInstantaneo: e.flujoInstantaneo,
        flujoAcumulado: e.flujoAcumulado,
        vaporFlashInstantaneo: e.vaporFlashInstantaneo,
        vaporFlashAcumulado: e.vaporFlashAcumulado,
        calorTotalInstantaneo: e.calorTotalInstantaneo,
        calorTotalAcumulado: e.calorTotalAcumulado,
        ahorroGasIntantaneo: e.ahorroGasIntantaneo,
        ahorroGasAcumulado: e.ahorroGasAcumulado,
        reduccionCO2: e.reduccionCO2,
      })
    })

    let buffer = await workbook.xlsx.writeBuffer()
    let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    let fileExtension = '.xlsx'
    let blob = new Blob([buffer], {type: fileType})

    saveAs(blob, `Registros` + fileExtension)
  }

  const selectFilterDate = (event) => {
    switch (event) {
      case 'today':
        setFilter(event)
        break
      case 'lastWeek':
        setFilter(event)
        break
      case 'dateRange':
        setFilter(event)
        break
      default:
        break
    }
  }

  const handleDateStartChange = (date) => {
    if (date !== null && date !== undefined) {
      setSelectedDateStart(date)
    }
  }

  const handleDateEndChange = (date) => {
    if (date !== null && date !== undefined) {
      setSelectedDateEnd(date)
    }
  }

  const handleClickDownload = async () => {
    let start = ''
    let end = ''
    let range = rangeDatetime(selectedDateStart, selectedDateEnd)
    if (filter === 'today') {
      start = range.today.start
      end = range.today.end
    } else {
      if (filter === 'lastWeek') {
        start = range.lastWeek.start
        end = range.lastWeek.end
      } else {
        if (filter === 'dateRange') {
          start = range.range.start
          end = range.range.end
        } else {
          start = range.today.start
          end = range.today.end
        }
      }
    }
    setIsloading(false)
    let response = await findGlobalData(start, end, '770917808/daf126atpt/gls')
    setIsloading(true)
    if (response.check) {
      if (response.detail.length > 0) {
        setDataRows(response.detail)
      }
    }
  }

  const handleChange = (field, event) => {
    switch (field) {
      case 'FilterTable':
        selectFilterDate(event)
        break
      default:
        break
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const constructor = () => {
    if (constructorHasRun) return
    // ------------

    // ------------
    setConstructorHasRun(true)
  }

  constructor()

  return (
    <Box sx={{width: '100%'}}>
      {' '}
      <Button aria-describedby={id} onClick={handleClick}>
        <Grid
          container
          direction={'row'}
          justifyContent={'center'}
          alignItems={'flex-end'}
          wrap='nowrap'
        >
          <Grid item>
            <img
              alt='logo_descarga'
              width={width === 'xs' ? '40px' : '37px'}
              height={width === 'xs' ? '40px' : '37px'}
              src={'/images/Boton_Descarga.png'}
            />
          </Grid>
          <Grid item sx={{position: 'relative', top: '-5px'}}>
            <Typography
              variant={'string'}
              sx={{textTransform: 'capitalize', color: 'dedicated.c14'}}
            >
              {width === 'xs' ? 'Descargar' : 'Descargar Histórico'}
            </Typography>
          </Grid>
        </Grid>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper sx={{padding: '8px'}}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* Type Filter */}
            <Grid item>
              <FormControl
                margin='dense'
                variant='outlined'
                sx={{
                  marginRight: '8px',
                  marginTop: '8px',
                  marginBottom: '8px',
                  width: '200px',
                }}
              >
                <InputLabel id='FilterTable'>Filtro</InputLabel>
                <Select
                  labelId='FilterTable'
                  id='filter-select'
                  value={filter}
                  onChange={(event) => handleChange('FilterTable', event.target.value)}
                  label='Filtro'
                >
                  <MenuItem value={'today'}>Hoy</MenuItem>
                  <MenuItem value={'lastWeek'}>Última Semana</MenuItem>
                  <MenuItem value={'dateRange'}>Rango de Fechas</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Box>
              {filter === 'dateRange' ? (
                <Box>
                  {/* Date Start */}
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label='Fecha Inicio'
                        inputFormat='dd/MM/yyyy'
                        value={selectedDateStart}
                        onChange={(value) => handleDateStartChange(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              marginRight: '8px',
                              marginTop: '8px',
                              marginBottom: '8px',
                              width: '200px',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {/* Date End */}
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label='Fecha Termino'
                        inputFormat='dd/MM/yyyy'
                        value={selectedDateEnd}
                        onChange={(value) => handleDateEndChange(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              marginRight: '8px',
                              marginTop: '8px',
                              marginBottom: '8px',
                              width: '200px',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Box>
              ) : null}
            </Box>
            <Button
              variant='outlined'
              startIcon={
                isloading ? (
                  <CloudDownloadIcon />
                ) : (
                  <CircularProgress size={20} color='success' />
                )
              }
              onClick={handleClickDownload}
              sx={{width: '100%'}}
            >
              Descargar
            </Button>
          </Grid>
        </Paper>
      </Popover>
    </Box>
  )
}

export default DownloadButton1
