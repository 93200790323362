import React from 'react'

const SVGEnergiaVerde = (props) => {
  return (
    <div>
      <svg
        width='110'
        height='110'
        viewBox='0 0 65.07 68.99'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <style></style>
        </defs>
        <g>
          <path
            fill='#4c9c2e'
            d='M30.32,13.1c-1,.79-1.88,3.75-1.6,5,.66,2.86.1,2.65,1.78,5.06,2.62,1,4.31,5.4,4.73,8s.69,4.53-.6,6.87c3-4.17,2.79-8.31,4.4-13.19,2.54,3.36,4.77,5.69,5.94,11.5a19.55,19.55,0,0,1-2,13.25,14.7,14.7,0,0,1-13.61,7.23A14.82,14.82,0,0,1,17,47.68a13.87,13.87,0,0,1,2.71-14.61,10.45,10.45,0,0,0,1.95-2.69A7.3,7.3,0,0,0,21.7,27C20.74,16.76,28.9,13.34,30.32,13.1ZM25,36.12c-4.36,4-3.36,8.29-1.73,11.46a9.19,9.19,0,0,0,8.86,4.82,9.77,9.77,0,0,0,8-6.67c.55-1.92,0-2.72-2-2.15s-4.79,2.59-7.47,1.63C22.17,42.19,25,35.68,25,36.12Z'
          />
        </g>
      </svg>
    </div>
  )
}

export default SVGEnergiaVerde
