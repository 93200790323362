import React, {useState, useEffect, forwardRef} from 'react'
import {Snackbar, Alert as MuiAlert} from '@mui/material'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

// Props :
//  variant : success, info, warning, error

const ContentSnackbar = ({status, message, variant, vertical, horizontal}) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (status) setOpen(true)
  }, [status])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
    >
      <Alert onClose={handleClose} severity={variant} sx={{width: '100%'}}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default ContentSnackbar
