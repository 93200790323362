import React, {useState, useEffect} from 'react'
import {Box, Paper, Grid, Typography, Avatar, Tooltip} from '@mui/material'
import useWidth from '../../util/useWidth'
import {formatDatetimeShort} from '../../util/datetime'

import {io} from 'socket.io-client'
import {serverDeviceControl} from '../../servers'
import {dataSave, dataLastSaved} from '../../services/devices/data'

import SVGEnergia from '../icon-image/svg-energia'
import SVGCo2 from '../icon-image/svg-co2'
import SVGGas from '../icon-image/svg-gas'
import SVGFlujo2 from '../icon-image/svg-flujo2'
import SVGEnergiaRecuperada from '../icon-image/svg-energia-recuperada'
import SVGEnergiaVerde from '../icon-image/svg-energia-verde'

const socket = io(serverDeviceControl)

const DisplayNumericImg1 = (props) => {
  const width = useWidth()
  const iconImg = props.iconImg
  const title = props.title
  const unit = props.unit
  const event = props.event
  const variable = props.variable

  const [constructorHasRun, setConstructorHasRun] = useState(false)

  const [value, setValue] = useState(0)
  const [valueTime, setValueTime] = useState(0)

  useEffect(() => {
    socket.on(event, (data) => {
      if (data !== undefined && data !== null) {
        dataSave(data)
        let dataEvent = data.value.find((e) => e.variableId === variable)
        if (dataEvent !== undefined && dataEvent !== null) {
          if (dataEvent.type === 'float') {
            let dataValue = parseFloat(dataEvent.value)
            setValue(dataValue.toLocaleString('es-CL', {minimumFractionDigits: 1}))
            let valueDataTime = formatDatetimeShort(data.timestamp)
            setValueTime(valueDataTime.datetime)
          }
        }
      }
    })
  }, [event, variable])

  const constructor = () => {
    if (constructorHasRun) return
    // ------------
    let getLastSaved = dataLastSaved(variable)
    if (getLastSaved !== null && getLastSaved !== undefined) {
      let valueSaved = parseFloat(getLastSaved.data.value)
      setValue(valueSaved.toLocaleString('es-CL', {minimumFractionDigits: 1}))
      let valueDataTime = formatDatetimeShort(getLastSaved.timestamp)
      setValueTime(valueDataTime.datetime)
    }
    // ------------
    setConstructorHasRun(true)
  }

  constructor()
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '300px',
        paddingTop: '20px',
        paddingBottom: '20px',
      }}
    >
      <Tooltip title={`Último registro: ${valueTime}`}>
        <Box
          sx={{
            width: '100%',
            height: '60px',
            backgroundColor: 'dedicated.c16',
            borderRadius: '8px',
            boxShadow: '1px 1px 1px rgba(68, 68, 68, 0.6)',
          }}
        >
          <Grid container wrap='nowrap'>
            <Grid item>
              <Paper
                elevation={6}
                sx={{
                  borderRadius: '100px',
                  position: 'relative',
                  left: '-20px',
                  ...(width === 'xs' ? {top: '-15px'} : {top: '-20px'}),
                }}
              >
                <Avatar
                  sx={{
                    ...(width === 'xs'
                      ? {width: '90px', height: '90px'}
                      : {width: '100px', height: '100px'}),
                    borderRadius: '50px',
                    background: '#ffffff',
                  }}
                >
                  {iconImg === 'icon-energia' ? (
                    <SVGEnergia />
                  ) : iconImg === 'icon-gas' ? (
                    <SVGGas />
                  ) : iconImg === 'icon-co2' ? (
                    <SVGCo2 />
                  ) : iconImg === 'icon-flujo2' ? (
                    <SVGFlujo2 />
                  ) : iconImg === 'icon-energia-recuperada' ? (
                    <SVGEnergiaRecuperada />
                  ) : iconImg === 'icon-energia-verde' ? (
                    <SVGEnergiaVerde />
                  ) : null}
                </Avatar>
              </Paper>
            </Grid>
            <Grid
              item
              container
              direction='row'
              justifyContent='flex-end'
              alignItems='center'
              sx={{
                height: '60px',
                position: 'relative',
                top: '-20px',
              }}
            >
              {/* Title */}
              <Grid item>
                <Typography variant='body2'>{title}</Typography>
              </Grid>
              {/* Data */}
              <Grid
                container
                item
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
                wrap='nowrap'
                sx={{
                  marginTop: '13px',
                }}
              >
                <Typography variant='h6' sx={{fontFamily: 'Arial'}}>
                  {<strong>{value}</strong>}
                </Typography>
                <Typography
                  variant='h6'
                  sx={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    fontFamily: 'Arial',
                  }}
                >
                  {<strong>{unit}</strong>}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Tooltip>
    </Box>
  )
}

export default DisplayNumericImg1
