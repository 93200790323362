import axios from 'axios'
import {serverDataControl} from '../../../servers'

export const authUser = async (email, password) => {
  try {
    let postDetail = {
      email: email,
      password: password,
    }
    let response = await axios({
      method: 'post',
      url: `${serverDataControl}/api/data/auth/user/login`,
      data: postDetail,
    })
    if (response !== null && response !== undefined) {
      return response.data
    } else {
      return {check: false, code: 400, detail: 'not data'}
    }
  } catch (error) {
    return {check: false, code: 400, detail: 'error'}
  }
}
