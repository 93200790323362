import React from 'react'

const SVGCo2 = (props) => {
  return (
    <div>
      <svg
        width='80'
        height='80'
        viewBox='-6 -8 105 105'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          className='cls-1'
          transform='matrix(1, 0, 0, 1, -10.923869295773926, -14.158039303988804)'
        >
          <g id='Capa_1' data-name='Capa 1'>
            <polygon
              className='cls-3'
              points='38.49 96.04 39.31 95.27 43.1 99.34 46.88 95.27 47.71 96.04 43.1 101 38.49 96.04'
              fill={'rgb(255, 255, 255)'}
            />
            <polygon
              className='cls-3'
              points='53.53 96.58 54.27 95.89 57.64 99.52 61.02 95.89 61.75 96.58 57.64 101 53.53 96.58'
              fill={'rgb(255, 255, 255)'}
            />
            <polygon
              className='cls-3'
              points='67.08 98.21 67.82 97.53 71.19 101.16 74.57 97.53 75.3 98.21 71.19 102.63 67.08 98.21'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-3'
              d='M33.83,68c-1.82,1.55-2.23,6.3-1.32,8.5s7.83,14.24,21.67,4.59c.61,2.11,7.44,8.37,15.39,6.49,9.61-2.28,10.58-9.38,9-12.9A13.57,13.57,0,0,0,88,69.15C93.73,60,86.92,51,80.88,49.61c-.61-3.89,0-6.16-5.24-10.49-4.51-3.76-13.28-3.86-17.2,2.65-11.52-8.86-18.66,2.51-18.66,2.51s-11.17-2.44-14.2,8.33C23.07,61.55,31.22,67.2,33.83,68Z'
              fill={'rgb(255, 255, 255)'}
            />
            <circle
              className='cls-3'
              cx='50.29'
              cy='27.13'
              r='1.68'
              fill={'rgb(255, 255, 255)'}
            />
            <circle
              className='cls-3'
              cx='60.89'
              cy='31.6'
              r='1.68'
              fill={'rgb(255, 255, 255)'}
            />
            <circle
              className='cls-3'
              cx='65.94'
              cy='20.36'
              r='1.68'
              fill={'rgb(255, 255, 255)'}
            />
            <circle
              className='cls-3'
              cx='76.47'
              cy='31.29'
              r='1.97'
              fill={'rgb(255, 255, 255)'}
            />
            <circle
              className='cls-3'
              cx='38.59'
              cy='18.11'
              r='1.68'
              fill={'rgb(255, 255, 255)'}
            />
            <circle
              className='cls-3'
              cx='40.03'
              cy='33.31'
              r='2.5'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-3'
              d='M51.62,57l-2.47,1.36a4.25,4.25,0,0,0-1.38-1A4.07,4.07,0,0,0,46.18,57a3.74,3.74,0,0,0-2.7,1,3.41,3.41,0,0,0-1,2.55,3.28,3.28,0,0,0,1,2.46A3.58,3.58,0,0,0,46,64a3.81,3.81,0,0,0,3.11-1.36l2.34,1.6a6.38,6.38,0,0,1-5.37,2.47,6.42,6.42,0,0,1-4.89-1.85,6,6,0,0,1-1.77-4.32,6.13,6.13,0,0,1,.86-3.17,6,6,0,0,1,2.4-2.27,7.14,7.14,0,0,1,3.44-.82,6.93,6.93,0,0,1,3.16.7A6,6,0,0,1,51.62,57Z'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-3'
              d='M59.29,54.12a6.24,6.24,0,0,1,3.14.84,6,6,0,0,1,2.3,2.27,6.13,6.13,0,0,1,.83,3.1,6.24,6.24,0,0,1-.83,3.13,6.06,6.06,0,0,1-2.27,2.28,6.23,6.23,0,0,1-3.16.82A5.9,5.9,0,0,1,55,64.75a6.26,6.26,0,0,1,.24-9A6,6,0,0,1,59.29,54.12Zm0,2.8a3,3,0,0,0-2.3,1,3.42,3.42,0,0,0-.92,2.46,3.47,3.47,0,0,0,.91,2.5,3.25,3.25,0,0,0,4.62,0,3.8,3.8,0,0,0,0-5A3.06,3.06,0,0,0,59.33,56.92Z'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-3'
              d='M70.28,57.76H68.11a4.31,4.31,0,0,1,1.19-3,4.19,4.19,0,0,1,4.7-.63,3.42,3.42,0,0,1,1.3,1.29,3.36,3.36,0,0,1,.49,1.72,5.17,5.17,0,0,1-.59,2.24A12.34,12.34,0,0,1,73,62.23l-1.31,1.38h4.16v2.06H67.79V64.61l3.62-3.69a10.5,10.5,0,0,0,1.74-2.13,3.11,3.11,0,0,0,.43-1.46,1.48,1.48,0,0,0-.45-1.11A1.58,1.58,0,0,0,72,55.78a1.53,1.53,0,0,0-1.19.53A2.19,2.19,0,0,0,70.28,57.76Z'
              fill={'rgb(255, 255, 255)'}
            />
            <line
              className='cls-4'
              x1='43.1'
              y1='80.19'
              x2='43.1'
              y2='85.69'
              fill={'none'}
              stroke={'rgb(149, 193, 31)'}
              strokeLinecap={'round'}
              strokeLinejoin={'round'}
              strokeWidth={'1.13px'}
            />
            <line
              className='cls-4'
              x1='43.1'
              y1='94.28'
              x2='43.1'
              y2='99.78'
              fill={'none'}
              stroke={'rgb(149, 193, 31)'}
              strokeLinecap={'round'}
              strokeLinejoin={'round'}
              strokeWidth={'1.13px'}
            />
            <polygon
              className='cls-5'
              points='38.49 96.04 39.31 95.27 43.1 99.34 46.88 95.27 47.71 96.04 43.1 101 38.49 96.04'
              fill={'rgb(149, 193, 31)'}
            />
            <line
              className='cls-6'
              x1='57.64'
              y1='84.48'
              x2='57.64'
              y2='89.37'
              fill={'none'}
              stroke={'rgb(149, 193, 31)'}
              strokeLinecap={'round'}
              strokeLinejoin={'round'}
            />
            <line
              className='cls-6'
              x1='57.64'
              y1='95.01'
              x2='57.64'
              y2='99.91'
              fill={'none'}
              stroke={'rgb(149, 193, 31)'}
              strokeLinecap={'round'}
              strokeLinejoin={'round'}
            />
            <polygon
              className='cls-5'
              points='53.53 96.58 54.27 95.89 57.64 99.52 61.02 95.89 61.75 96.58 57.64 101 53.53 96.58'
              fill={'rgb(149, 193, 31)'}
            />
            <line
              className='cls-7'
              x1='71.19'
              y1='86.11'
              x2='71.19'
              y2='101.54'
              fill={'none'}
              stroke={'rgb(149, 193, 31)'}
              strokeDasharray={'6.12px, 6.12px'}
              strokeLinecap={'round'}
              strokeLinejoin={'round'}
            />
            <polygon
              className='cls-5'
              points='67.08 98.21 67.82 97.53 71.19 101.16 74.57 97.53 75.3 98.21 71.19 102.63 67.08 98.21'
              fill={'rgb(149, 193, 31)'}
            />
            <path
              className='cls-5'
              d='M33.83,68c-1.82,1.55-2.23,6.3-1.32,8.5s7.83,14.24,21.67,4.59c.61,2.11,7.44,8.37,15.39,6.49,9.61-2.28,10.58-9.38,9-12.9A13.57,13.57,0,0,0,88,69.15C93.73,60,86.92,51,80.88,49.61c-.61-3.89,0-6.16-5.24-10.49-4.51-3.76-13.28-3.86-17.2,2.65-11.52-8.86-18.66,2.51-18.66,2.51s-11.17-2.44-14.2,8.33C23.07,61.55,31.22,67.2,33.83,68Z'
              fill={'rgb(149, 193, 31)'}
            />
            <circle
              className='cls-5'
              cx='50.29'
              cy='27.13'
              r='1.68'
              fill={'rgb(149, 193, 31)'}
            />
            <circle
              className='cls-5'
              cx='60.89'
              cy='31.6'
              r='1.68'
              fill={'rgb(149, 193, 31)'}
            />
            <circle
              className='cls-5'
              cx='65.94'
              cy='20.36'
              r='1.68'
              fill={'rgb(149, 193, 31)'}
            />
            <circle
              className='cls-5'
              cx='76.47'
              cy='31.29'
              r='1.97'
              fill={'rgb(149, 193, 31)'}
            />
            <circle
              className='cls-5'
              cx='38.59'
              cy='18.11'
              r='1.68'
              fill={'rgb(149, 193, 31)'}
            />
            <circle
              className='cls-5'
              cx='40.03'
              cy='33.31'
              r='2.5'
              fill={'rgb(149, 193, 31)'}
            />
            <path
              className='cls-3'
              d='M51.62,57l-2.47,1.36a4.25,4.25,0,0,0-1.38-1A4.07,4.07,0,0,0,46.18,57a3.74,3.74,0,0,0-2.7,1,3.41,3.41,0,0,0-1,2.55,3.28,3.28,0,0,0,1,2.46A3.58,3.58,0,0,0,46,64a3.81,3.81,0,0,0,3.11-1.36l2.34,1.6a6.38,6.38,0,0,1-5.37,2.47,6.42,6.42,0,0,1-4.89-1.85,6,6,0,0,1-1.77-4.32,6.13,6.13,0,0,1,.86-3.17,6,6,0,0,1,2.4-2.27,7.14,7.14,0,0,1,3.44-.82,6.93,6.93,0,0,1,3.16.7A6,6,0,0,1,51.62,57Z'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-3'
              d='M59.29,54.12a6.24,6.24,0,0,1,3.14.84,6,6,0,0,1,2.3,2.27,6.13,6.13,0,0,1,.83,3.1,6.24,6.24,0,0,1-.83,3.13,6.06,6.06,0,0,1-2.27,2.28,6.23,6.23,0,0,1-3.16.82A5.9,5.9,0,0,1,55,64.75a6.26,6.26,0,0,1,.24-9A6,6,0,0,1,59.29,54.12Zm0,2.8a3,3,0,0,0-2.3,1,3.42,3.42,0,0,0-.92,2.46,3.47,3.47,0,0,0,.91,2.5,3.25,3.25,0,0,0,4.62,0,3.8,3.8,0,0,0,0-5A3.06,3.06,0,0,0,59.33,56.92Z'
              fill={'rgb(255, 255, 255)'}
            />
            <path
              className='cls-3'
              d='M70.28,57.76H68.11a4.31,4.31,0,0,1,1.19-3,4.19,4.19,0,0,1,4.7-.63,3.42,3.42,0,0,1,1.3,1.29,3.36,3.36,0,0,1,.49,1.72,5.17,5.17,0,0,1-.59,2.24A12.34,12.34,0,0,1,73,62.23l-1.31,1.38h4.16v2.06H67.79V64.61l3.62-3.69a10.5,10.5,0,0,0,1.74-2.13,3.11,3.11,0,0,0,.43-1.46,1.48,1.48,0,0,0-.45-1.11A1.58,1.58,0,0,0,72,55.78a1.53,1.53,0,0,0-1.19.53A2.19,2.19,0,0,0,70.28,57.76Z'
              fill={'rgb(255, 255, 255)'}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default SVGCo2
