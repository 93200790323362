import React from 'react'

const SVGEnergia = (props) => {
  return (
    <div>
      <svg
        width='89.375px'
        height='130px'
        viewBox='-15 -25 89.375 130'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <linearGradient
            id='Degradado_1'
            x1='60.34'
            y1='22.45'
            x2='60.34'
            y2='93.05'
            gradientUnits='userSpaceOnUse'
            gradientTransform='matrix(1, 0, 0, 1, -150.375946, -66.669167)'
          >
            <stop offset='0' stopColor='#d82216' />
            <stop offset='0.97' stopColor='#f8a913' />
          </linearGradient>
        </defs>
        <g
          className='cls-1'
          transform='matrix(1.0000000000000002, 0, 0, 1.0000000000000002, 145.9842398462331, 45.38706656377114)'
        >
          <g
            id='Capa_1'
            data-name='Capa 1'
            transform='matrix(1, 0, 0, 1, -28.195483, 3.132831)'
          >
            <path
              className='cls-4'
              d='M -90.565 -44.219 C -92.225 -42.949 -93.565 -38.219 -93.145 -36.159 C -92.085 -31.549 -92.985 -31.889 -90.275 -27.999 C -86.055 -26.359 -83.325 -19.299 -82.655 -15.049 C -81.985 -10.799 -81.535 -7.749 -83.655 -3.979 C -78.775 -10.699 -79.165 -17.379 -76.555 -25.249 C -72.375 -19.829 -68.835 -16.069 -66.955 -6.669 C -65.485 0.614 -66.643 8.182 -70.225 14.691 C -74.757 22.399 -83.242 26.901 -92.165 26.331 C -101.079 25.546 -108.803 19.834 -112.165 11.541 C -115.395 3.509 -113.697 -5.666 -107.805 -12.009 C -106.53 -13.274 -105.467 -14.736 -104.655 -16.339 C -103.975 -18.059 -104.335 -19.979 -104.515 -21.809 C -106.025 -38.329 -92.855 -43.839 -90.565 -44.219 Z M -99.235 -7.109 C -106.235 -0.659 -104.655 6.271 -102.025 11.381 C -99.226 16.583 -93.612 19.634 -87.725 19.151 C -81.711 18.357 -76.691 14.173 -74.825 8.401 C -73.925 5.291 -74.825 4.011 -78.105 4.931 C -81.385 5.851 -85.825 9.111 -90.105 7.551 C -103.725 2.681 -99.155 -7.809 -99.235 -7.109 Z'
              fill={`url(#Degradado_1)`}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

export default SVGEnergia
