var dataTemporal = []

export const dataSave = (data) => {
  dataTemporal.length = 0
  dataTemporal.push({value: data.value, timestamp: data.timestamp})
  return true
}

export const dataLastSaved = (variableId) => {
  if (dataTemporal !== null && dataTemporal !== undefined) {
    if (dataTemporal.length > 0) {
      let dataSaved = dataTemporal[0].value.find((e) => e.variableId === variableId)
      if (dataSaved !== null && dataSaved !== undefined) {
        return {data: dataSaved, timestamp: dataTemporal[0].timestamp}
      } else {
        return null
      }
    } else {
      return null
    }
  } else {
    return null
  }
}
