export const validateEmail = (email) => {
  if (
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
      email
    )
  ) {
    return true
  } else {
    return false
  }
}

export const validateEmails = (emails) => {
  let arrayEmails = emails.split(',')
  let validation = true
  for (let i = 0; i < arrayEmails.length; i++) {
    let e = arrayEmails[i].trimStart()
    let respValidate = validateEmail(e)
    if (respValidate === false) {
      validation = false
    }
  }
  return validation
}
