import React, {useEffect, useState} from 'react'
import {Box, Grid, Typography, Button} from '@mui/material'
import {PowerSettingsNew as PowerSettingsNewIcon} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'

import useWidth from '../../../util/useWidth'
import ClockDatetime1 from '../../../components/clock/clock-datetime-1'
import DisplayNumericImg1 from '../../../components/display/display-numeric-img-1'
import DownloadButton1 from '../../../components/download/downloadButton-1/download-button-1'
import GaugeFixed1 from '../../../components/gauge/gauge-fixed-1'
import {getDataSession, closeSession} from '../../../services/auth/session'

const classes = {
  root: {
    width: '100%',
    height: '100vh',
  },
  title: {
    fontFamily: 'Arial',
    color: 'dedicated.c15',
  },
  titleLogo: {
    height: '20vh',
    marginTop: '8px',
    marginRight: '8px',
    marginLeft: '8px',
  },
  colorControlBottom: {
    color: 'dedicated.c15',
  },
}

const ControlBottom = (props) => {
  const onClick = props.handleClickClose

  const handleClickClose = () => {
    onClick()
  }

  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        container
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ClockDatetime1 />
      </Grid>
      <Grid
        container
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Button onClick={handleClickClose}>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <PowerSettingsNewIcon sx={classes.colorControlBottom} />
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={classes.colorControlBottom}
            >
              <Typography variant={'string'} sx={{textTransform: 'capitalize'}}>
                Cerrar Sesión
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  )
}

const FlujoPurgasView = (props) => {
  const width = useWidth()
  const navigate = useNavigate()
  const [constructorHasRun, setConstructorHasRun] = useState(false)
  const [session, setSession] = useState(true)

  const [widthMiddleBox, setWidthMiddleBox] = useState('5%')
  const [widthLeftBox, setWidthLeftBox] = useState('95%')
  const [heightTopBox, setHeightTopBox] = useState('45%')
  const [heightBottomBox, setHeightBottomBox] = useState('45%')

  useEffect(() => {
    switch (width) {
      case 'xl':
        setWidthMiddleBox('3%')
        setWidthLeftBox('97%')
        setHeightTopBox('95%')
        setHeightBottomBox('80%')
        break
      case 'lg':
        setWidthMiddleBox('3%')
        setWidthLeftBox('97%')
        setHeightTopBox('90%')
        setHeightBottomBox('80%')
        break
      case 'md':
        setWidthMiddleBox('5%')
        setWidthLeftBox('95%')
        setHeightTopBox('90%')
        setHeightBottomBox('80%')
        break
      case 'sm':
        setWidthMiddleBox('7%')
        setWidthLeftBox('93%')
        setHeightTopBox('45%')
        setHeightBottomBox('40%')
        break
      case 'xs':
        setWidthMiddleBox('9%')
        setWidthLeftBox('91%')
        setHeightTopBox('45%')
        setHeightBottomBox('40%')
        break
      default:
        setWidthMiddleBox('5%')
        setWidthLeftBox('95%')
        setHeightTopBox('45%')
        setHeightBottomBox('40%')
        break
    }
  }, [widthMiddleBox, widthLeftBox, heightTopBox, heightBottomBox, width])

  useEffect(() => {
    if (!session) {
      navigate('/view/sign-in')
    }
  }, [session, navigate])

  const validSession = () => {
    let getData = getDataSession()
    if (!getData.get) {
      setSession(false)
    }
  }
  const handleClickClose = () => {
    closeSession()
    setSession(false)
  }

  const handleClickNavNext = () => {
    navigate('/view/totals')
  }

  const handleClickNavBefore = () => {
    navigate('/view/energy-recovery')
  }

  const constructor = () => {
    if (constructorHasRun) return
    // ------------
    validSession()
    // ------------
    setConstructorHasRun(true)
  }

  constructor()

  return (
    <Box sx={classes.root}>
      <Grid
        container
        direction={'row'}
        justifyContent={'center'}
        alignItems={'flex-start'}
      >
        {/* Container Left - Title */}
        <Grid
          container
          item
          lg={3}
          md={3}
          sm={4}
          xs={12}
          direction={'row'}
          justifyContent={'flex-start'}
          sx={{
            ...(width === 'xs'
              ? {height: '22vh'}
              : width === 'sm'
              ? {height: '150vh'}
              : {height: '100vh'}),
            backgroundColor: 'dedicated.c9',
          }}
        >
          {/* Logo - Img */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={5}
            direction={'row'}
            justifyContent={width === 'xs' ? 'flex-start' : 'center'}
            alignItems={width === 'xs' ? 'center' : 'flex-start'}
            sx={classes.titleLogo}
          >
            <Box>
              <img
                alt='logo_thermal'
                maxwidth='100px'
                height={width === 'xs' ? '63px' : '100px'}
                src='/images/Thermal_Esco.png'
              />
            </Box>
            <Box>
              <img
                alt='logo_thermal'
                maxwidth='100px'
                height={width === 'xs' ? '63px' : '100px'}
                src='/images/Aconcagua_Foods.png'
              />
            </Box>
          </Grid>
          {/* Title */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={6}
            direction={'row'}
            justifyContent={width === 'xs' ? 'flex-start' : 'center'}
            alignItems={'center'}
            sx={{
              ...(width === 'xs'
                ? {height: '20vh', marginTop: '8px', marginBottom: '8px'}
                : {height: '70vh', marginRight: '8px', marginLeft: '8px'}),
            }}
          >
            <Box
              sx={{
                ...(width === 'xs'
                  ? {
                      marginLeft: '8px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }
                  : {marginLeft: '30px', paddingLeft: '20px', paddingRight: '10px'}),
                borderLeft: '5px solid #ffffff',
              }}
            >
              <Typography variant={width === 'xs' ? 'body2' : 'body1'} sx={classes.title}>
                SISTEMA DE RECUPERACIÓN DE CALOR DE PURGAS <br />
                <strong>CALDERAS ACONCAGUA FOODS</strong>
              </Typography>
            </Box>
          </Grid>
          {/* Controls */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={width === 'xs' ? 'flex-end' : 'center'}
            alignItems='flex-end'
            sx={{
              ...(width === 'xs'
                ? {
                    display: 'none',
                  }
                : {
                    display: 'flex',
                    marginRight: '8px',
                    marginLeft: '8px',
                  }),
            }}
          >
            <ControlBottom handleClickClose={handleClickClose} />
          </Grid>
        </Grid>
        {/* Container Right - Display */}
        <Grid
          container
          item
          lg={9}
          md={9}
          sm={8}
          xs={12}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{
            ...(width === 'xs'
              ? {
                  height: '160vh',
                }
              : width === 'sm'
              ? {
                  height: '150vh',
                }
              : {
                  height: '100vh',
                }),
          }}
        >
          {/* Container Top */}
          <Grid
            container
            item
            sx={{
              ...(width === 'xs'
                ? {height: '80vh'}
                : width === 'sm'
                ? {height: '75vh'}
                : {height: '50vh'}),
              width: '100%',
            }}
          >
            {/* Left */}
            <Grid
              item
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              sx={{
                width: widthMiddleBox,
                backgroundColor: 'dedicated.c10',
              }}
            >
              <Typography
                variant={'subtitle1'}
                sx={{transform: 'rotate(270deg)', color: 'dedicated.c15'}}
              >
                TEMPERATURA
              </Typography>
            </Grid>
            {/* Right */}
            <Grid
              container
              item
              sx={{
                ...(width === 'xs'
                  ? {
                      paddingTop: '15px',
                      paddingBottom: '5px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }
                  : width === 'sm'
                  ? {
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }
                  : width === 'md'
                  ? {
                      paddingTop: '15px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }
                  : width === 'lg'
                  ? {
                      paddingTop: '25px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }
                  : {
                      paddingTop: '25px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }),
                width: widthLeftBox,
                backgroundColor: 'dedicated.c15',
              }}
            >
              {/* Title */}
              <Grid container item lg={12} md={12} sm={12} xs={12} sx={{height: '10%'}}>
                <Typography variant={'h6'}>
                  <strong>FLUJO PURGAS</strong>
                </Typography>
              </Grid>
              {/* Content - Gauge 1 */}
              <Grid
                container
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{height: heightTopBox, padding: '10px'}}
              >
                <GaugeFixed1
                  backgroundImg='/images/gauge_azul.png'
                  centerImg='/images/icono-temperatura.png'
                  minRange='0'
                  maxRange='100'
                  title='Temperatura de Entrada'
                  unit='°C'
                  alignVertival={true}
                  event={'datadevice/770917808/dAF126AtpT/gl'}
                  variable={'vTfrfn83lR'}
                />
              </Grid>
              {/* Content - Gauge 2 */}
              <Grid
                container
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{height: heightTopBox, padding: '10px'}}
              >
                <GaugeFixed1
                  backgroundImg='/images/gauge_rojo.png'
                  centerImg='/images/icono-temperatura.png'
                  minRange='0'
                  maxRange='100'
                  title='Temperatura de Salida'
                  unit='°C'
                  alignVertival={true}
                  event={'datadevice/770917808/dAF126AtpT/gl'}
                  variable={'vTcaY3u58d'}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Container Bottom */}
          <Grid
            container
            item
            sx={{
              ...(width === 'xs'
                ? {height: '80vh'}
                : width === 'sm'
                ? {height: '75vh'}
                : {height: '50vh'}),
              width: '100%',
            }}
          >
            {/* Left */}
            <Grid
              item
              container
              direction='row'
              justifyContent='center'
              alignItems='center'
              sx={{
                width: widthMiddleBox,
                backgroundColor: 'dedicated.c8',
              }}
            >
              <Typography
                variant={'subtitle1'}
                sx={{transform: 'rotate(270deg)', color: 'dedicated.c15'}}
              >
                FLUJO
              </Typography>
            </Grid>
            {/* Right */}
            <Grid
              container
              item
              sx={{
                ...(width === 'xs'
                  ? {
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      paddingLeft: '5px',
                      paddingRight: '5px',
                    }
                  : width === 'sm'
                  ? {
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }
                  : width === 'md'
                  ? {
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }
                  : width === 'lg'
                  ? {
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }
                  : {
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }),
                width: widthLeftBox,
                backgroundColor: 'dedicated.c15',
              }}
            >
              {/* Content - Gauge 3 */}
              <Grid
                container
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{height: heightBottomBox, padding: '10px'}}
              >
                <GaugeFixed1
                  backgroundImg='/images/gauge_azul.png'
                  centerImg='/images/icono_flujo1.png'
                  minRange='0'
                  maxRange='100'
                  title='Flujo Instantáneo'
                  unit='m3/h'
                  alignVertival={true}
                  event={'datadevice/770917808/dAF126AtpT/gl'}
                  variable={'vCamvKS788'}
                />
              </Grid>
              {/* Content - Display 4 */}
              <Grid
                container
                item
                lg={6}
                md={6}
                sm={12}
                xs={12}
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{
                  ...(width === 'xl'
                    ? {paddingLeft: '100px', paddingRight: '100px'}
                    : width === 'lg'
                    ? {paddingLeft: '50px', paddingRight: '50px'}
                    : width === 'md'
                    ? {paddingLeft: '10px', paddingRight: '10px'}
                    : width === 'sm'
                    ? {
                        paddingLeft: '40px',
                        paddingRight: '40px',
                        paddingTop: '40px',
                        paddingBottom: '40px',
                      }
                    : {
                        paddingLeft: '25px',
                        paddingRight: '15px',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                      }),
                  height: heightBottomBox,
                }}
              >
                <DisplayNumericImg1
                  iconImg={'icon-flujo2'}
                  title={'Flujo Acumulado'}
                  unit={'m3'}
                  event={'datadevice/770917808/dAF126AtpT/gl'}
                  variable={'vComrU054h'}
                />
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                direction='row'
                justifyContent='center'
                alignItems='center'
                sx={{height: '60px'}}
              >
                {/* Download - Navegate */}
                <Grid
                  container
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  direction={'row'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <DownloadButton1 />
                </Grid>
                <Grid
                  container
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  direction={'row'}
                  justifyContent={'flex-end'}
                  alignItems={'center'}
                >
                  <Button onClick={handleClickNavNext}>
                    <img
                      alt='logo_nav'
                      width={width === 'xs' ? '40px' : '45px'}
                      height={width === 'xs' ? '40px' : '45px'}
                      style={{transform: 'rotate(180deg)'}}
                      src={'/images/Flecha_avanza.png'}
                    />
                  </Button>
                  <Button onClick={handleClickNavBefore}>
                    <img
                      alt='logo_nav'
                      width={width === 'xs' ? '40px' : '45px'}
                      height={width === 'xs' ? '40px' : '45px'}
                      style={{transform: 'rotate(0deg)'}}
                      src={'/images/Flecha_avanza.png'}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Container Bottom (Only XS) - Clock and Logout */}
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction={'row'}
          justifyContent={'center'}
          sx={{
            ...(width === 'xs'
              ? {
                  display: 'flex',
                }
              : {display: 'none'}),
            height: '10vh',
            backgroundColor: 'dedicated.c9',
          }}
        >
          <ControlBottom handleClickClose={handleClickClose} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default FlujoPurgasView
