import React, {useState, useEffect} from 'react'
import {Box, Grid, Typography, Button} from '@mui/material'
import {PowerSettingsNew as PowerSettingsNewIcon} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'

import useWidth from '../../../util/useWidth'
import ClockDatetime1 from '../../../components/clock/clock-datetime-1'
import DisplayNumericImg1 from '../../../components/display/display-numeric-img-1'
import DownloadButton1 from '../../../components/download/downloadButton-1/download-button-1'
import {getDataSession, closeSession} from '../../../services/auth/session'

const classes = {
  root: {
    width: '100%',
    height: '100vh',
  },
  title: {
    fontFamily: 'Arial',
    color: 'dedicated.c15',
  },
  titleLogo: {
    height: '20vh',
    marginTop: '8px',
    marginRight: '8px',
    marginLeft: '8px',
  },
  colorControlBottom: {
    color: 'dedicated.c15',
  },
}

const ControlBottom = (props) => {
  const onClick = props.handleClickClose

  const handleClickClose = () => {
    onClick()
  }

  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      direction={'row'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Grid
        container
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ClockDatetime1 />
      </Grid>
      <Grid
        container
        item
        lg={6}
        md={6}
        sm={6}
        xs={6}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Button onClick={handleClickClose}>
          <Grid
            container
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <PowerSettingsNewIcon sx={classes.colorControlBottom} />
            </Grid>
            <Grid
              container
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={classes.colorControlBottom}
            >
              <Typography variant={'string'} sx={{textTransform: 'capitalize'}}>
                Cerrar Sesión
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </Grid>
  )
}

const TotalesAcumuladosView = (props) => {
  const width = useWidth()
  const navigate = useNavigate()

  const [constructorHasRun, setConstructorHasRun] = useState(false)
  const [session, setSession] = useState(true)

  useEffect(() => {
    if (!session) {
      navigate('/view/sign-in')
    }
  }, [session, navigate])

  const validSession = () => {
    let getData = getDataSession()
    if (!getData.get) {
      setSession(false)
    }
  }

  const handleClickClose = () => {
    closeSession()
    setSession(false)
  }

  const handleClickNav = () => {
    navigate('/view/flows')
  }

  const constructor = () => {
    if (constructorHasRun) return
    // ------------
    validSession()
    // ------------
    setConstructorHasRun(true)
  }

  constructor()

  return (
    <Box sx={classes.root}>
      <Grid
        container
        direction={'row'}
        justifyContent={'center'}
        alignItems={'flex-start'}
      >
        {/* Container Left - Title */}
        <Grid
          container
          item
          lg={3}
          md={3}
          sm={4}
          xs={12}
          direction={'row'}
          justifyContent={'flex-start'}
          sx={{
            ...(width === 'xs' ? {minHeight: '22vh'} : {minHeight: '100vh'}),
            backgroundColor: 'dedicated.c9',
          }}
        >
          {/* Logo - Img */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={5}
            direction={'row'}
            justifyContent={width === 'xs' ? 'flex-start' : 'center'}
            alignItems={width === 'xs' ? 'center' : 'flex-start'}
            sx={classes.titleLogo}
          >
            <Box>
              <img
                alt='logo_thermal'
                maxwidth='100px'
                height={width === 'xs' ? '63px' : '100px'}
                src='/images/Thermal_Esco.png'
              />
            </Box>
            <Box>
              <img
                alt='logo_thermal'
                maxwidth='100px'
                height={width === 'xs' ? '63px' : '100px'}
                src='/images/Aconcagua_Foods.png'
              />
            </Box>
          </Grid>
          {/* Title */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={6}
            direction={'row'}
            justifyContent={width === 'xs' ? 'flex-start' : 'center'}
            alignItems={'center'}
            sx={{
              ...(width === 'xs'
                ? {height: '20vh', marginTop: '8px', marginBottom: '8px'}
                : {height: '70vh', marginRight: '8px', marginLeft: '8px'}),
            }}
          >
            <Box
              sx={{
                ...(width === 'xs'
                  ? {marginLeft: '8px', paddingLeft: '8px', paddingRight: '8px'}
                  : {marginLeft: '30px', paddingLeft: '20px', paddingRight: '10px'}),
                borderLeft: '5px solid #ffffff',
              }}
            >
              <Typography variant={width === 'xs' ? 'body2' : 'body1'} sx={classes.title}>
                SISTEMA DE RECUPERACIÓN DE CALOR DE PURGAS <br />
                <strong>CALDERAS ACONCAGUA FOODS</strong>
              </Typography>
            </Box>
          </Grid>
          {/* Controls */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={width === 'xs' ? 'flex-start' : 'center'}
            alignItems='flex-end'
            sx={{
              ...(width === 'xs'
                ? {
                    display: 'none',
                  }
                : {
                    display: 'flex',
                    marginRight: '8px',
                    marginLeft: '8px',
                  }),
            }}
          >
            <ControlBottom handleClickClose={handleClickClose} />
          </Grid>
        </Grid>
        {/* Container Right - Display */}
        <Grid
          container
          item
          lg={9}
          md={9}
          sm={8}
          xs={12}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          sx={{
            ...(width === 'xs'
              ? {minHeight: '70vh', paddingLeft: '40px', paddingRight: '30px'}
              : {minHeight: '100vh', paddingLeft: '40px', paddingRight: '40px'}),
          }}
        >
          {/* Title -Display */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{
              width: '100%',
              height: '20vh',
              // paddingBottom: '30px',
            }}
          >
            <Typography variant={'h6'}>
              <strong>TOTALES ACUMULADOS</strong>
            </Typography>
          </Grid>
          {/* Display 1 */}
          <Grid
            container
            item
            lg={7}
            md={7}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{
              height: '20vh',
            }}
          >
            <DisplayNumericImg1
              iconImg={'icon-energia'}
              title={'Energía Total Ahorrada'}
              unit={'KWh'}
              event={'datadevice/770917808/dAF126AtpT/gl'}
              variable={'vEnr9Zv44U'}
            />
          </Grid>
          {/* Display 2 */}
          <Grid
            container
            item
            lg={7}
            md={7}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{
              height: '20vh',
            }}
          >
            <DisplayNumericImg1
              iconImg={'icon-gas'}
              title={'Gas Natural Ahorrado'}
              unit={'Nm3'}
              event={'datadevice/770917808/dAF126AtpT/gl'}
              variable={'vGnaw679Lv'}
            />
          </Grid>
          {/* Display 3 */}
          <Grid
            container
            item
            lg={7}
            md={7}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            sx={{
              height: '20vh',
            }}
          >
            <DisplayNumericImg1
              iconImg={'icon-co2'}
              title={'CO2 Reducido'}
              unit={'Ton'}
              event={'datadevice/770917808/dAF126AtpT/gl'}
              variable={'vRCozRZ084'}
            />
          </Grid>
          {/* Download - Navegate */}
          <Grid
            container
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            direction={'row'}
            justifyContent={'flex-start'}
            alignItems={'flex-end'}
            sx={{
              width: '100%',
              height: '20vh',
            }}
          >
            <Grid
              container
              item
              lg={8}
              md={8}
              sm={8}
              xs={8}
              direction={'row'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <DownloadButton1 />
            </Grid>
            <Grid
              container
              item
              lg={4}
              md={4}
              sm={4}
              xs={4}
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              <Button onClick={handleClickNav}>
                <img
                  alt='logo_nav'
                  width='45px'
                  height='45px'
                  src={'/images/Flecha_avanza.png'}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* Container Bottom (Only XS) - Clock and Logout */}
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction={'row'}
          justifyContent={'center'}
          sx={{
            ...(width === 'xs'
              ? {
                  display: 'flex',
                }
              : {display: 'none'}),
            height: '10vh',
            backgroundColor: 'dedicated.c9',
          }}
        >
          <ControlBottom handleClickClose={handleClickClose} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default TotalesAcumuladosView
