export const scl = (inValue, inMin, inMax, outMin, outMax, outlimit) => {
  let calculate = -(((inMax - inValue) / (inMax - inMin)) * (outMax - outMin)) + outMax
  if (outlimit) {
    if (calculate < outMin) {
      calculate = outMin
    }
    if (calculate > outMax) {
      calculate = outMax
    }
  }
  return calculate
}
