import React, {useState} from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  LinearProgress,
} from '@mui/material'
import {Email as EmailIcon, Lock as LockIcon} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'

import ContentSnackbar from '../../../components/snackbar-message/index'
import {validateEmails} from '../../../util/validateEmails'
import {authUser} from '../Services/authentication'

import {saveDataSession} from '../../../services/auth/session'

import {io} from 'socket.io-client'
import {serverDeviceControl} from '../../../servers'

const socket = io(serverDeviceControl)

const classes = {
  root: {
    width: '100%',
  },
  logoTitle: {
    marginTop: '50px',
  },
  title: {
    fontFamily: 'Arial',
  },
  inputText: {
    width: '100%',
  },
  containerPaper: {
    width: '345px',
    height: '260px',
    backgroundColor: 'dedicated.c10',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    paddingTop: '20px',
  },
  containerInputEmail: {
    backgroundColor: 'dedicated.c15',
    borderRadius: '5px',
    marginBottom: '45px',
  },
  containerInputPassword: {
    backgroundColor: 'dedicated.c15',
    borderRadius: '5px',
    marginBottom: '65px',
  },
  containerButton: {
    width: '100%',
  },
  Button: {
    width: '100%',
    height: '45px',
    backgroundColor: 'dedicated.c9',
    '&:hover': {
      backgroundColor: 'dedicated.c8',
      borderColor: 'dedicated.c8',
      boxShadow: 'none',
    },
  },
}

let keySetTimeOutValidEmail
let keySetTimeOutValidPassword

const SignInView = (props) => {
  const navigate = useNavigate()

  const [constructorHasRun, setConstructorHasRun] = useState(false)

  const [inputEmail, setInputEmail] = useState('')
  const [inputPassword, setInputPassword] = useState('')
  const [errorEmail, setErrorEmail] = useState(true)
  const [errorPassword, setErrorPassword] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    variant: 'info',
    vertical: 'top',
    horizontal: 'center',
  })

  const changeEmail = async (value) => {
    let valid = validateEmails(value)
    setInputEmail(value)
    clearTimeout(keySetTimeOutValidEmail)

    keySetTimeOutValidEmail = setTimeout(() => {
      if (valid) {
        setErrorEmail(true)
      } else {
        setErrorEmail(false)
      }
    }, 500)
  }
  const changePassword = async (value) => {
    let valid = false
    if (value !== '') {
      valid = true
    }
    setInputPassword(value)
    clearTimeout(keySetTimeOutValidPassword)

    keySetTimeOutValidPassword = setTimeout(() => {
      if (valid) {
        setErrorPassword(true)
      } else {
        setErrorPassword(false)
      }
    }, 500)
  }

  const checkAuthUser = async () => {
    if (errorEmail && errorPassword && inputEmail !== '' && inputPassword !== '') {
      setIsLoading(true)
      let response = await authUser(inputEmail, inputPassword)
      setIsLoading(false)
      if (response !== null && response !== undefined) {
        if (response.check) {
          let resSave = saveDataSession(response.detail)
          if (resSave.save) {
            // ----Get Last Data----
            socket.emit('publish/getLastGlobalData', 'get')
            // ---------------------
            navigate('/view/totals')
          } else {
            openSnackbar(
              'Ha ocurrido un error... Si el error persiste favor contacte con el equipo de soporte',
              'error',
              'top',
              'right'
            )
          }
        } else {
          if (response.code === 400) {
            openSnackbar(
              'Ha ocurrido un error... Si el error persiste favor contacte con el equipo de soporte',
              'error',
              'top',
              'right'
            )
          }
          if (response.code === 401) {
            openSnackbar(
              'La contraseña es incorrecta o el usuario esta deshabilitado',
              'warning',
              'top',
              'right'
            )
          }
        }
      }
    } else {
      openSnackbar(
        'Favor verifique el correcto ingreso de usuario y/o contraseña',
        'warning',
        'top',
        'right'
      )
    }
  }

  const openSnackbar = async (message, variant, vertical, horizontal) => {
    let newSnackbar = {
      open: true,
      message: message,
      variant: variant,
      vertical: vertical,
      horizontal: horizontal,
    }
    setSnackbar(newSnackbar)

    try {
      await setTimeout(() => {
        let newSnackbarClose = {
          open: false,
          message: message,
          variant: variant,
          vertical: vertical,
          horizontal: horizontal,
        }
        setSnackbar(newSnackbarClose)
      }, 50)
    } catch (error) {
      console.log(
        'Ha ocurrido un error... Si el error persiste favor contacte con el equipo de soporte'
      )
    }
  }
  const handleClick = () => {
    checkAuthUser()
  }

  const handleChange = (field, value) => {
    switch (field) {
      case 'email':
        changeEmail(value)
        break
      case 'password':
        changePassword(value)
        break
      default:
        break
    }
  }

  const constructor = () => {
    if (constructorHasRun) return
    // ------------

    // ------------
    setConstructorHasRun(true)
  }

  constructor()

  return (
    <Box sx={classes.root}>
      <ContentSnackbar
        status={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        vertical={snackbar.vertical}
        horizontal={snackbar.horizontal}
      />
      <Grid container direction={'row'} justifyContent={'center'}>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction={'row'}
          justifyContent={'center'}
          sx={classes.logoTitle}
        >
          {/* Head - Logo */}
          <Box>
            <img
              alt='logo_thermal'
              maxwidth='345px'
              height='110px'
              src='/images/Thermal_Esco_Horizontal.png'
            />
          </Box>
        </Grid>
        {/* Head - Tittle */}
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction={'row'}
          justifyContent={'center'}
        >
          <Typography variant='h6' component='h6' sx={classes.title}>
            INICIAR SESIÓN
          </Typography>
        </Grid>
        <Grid
          container
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          direction={'row'}
          justifyContent={'center'}
        >
          <Paper elevation={0} sx={classes.containerPaper}>
            {/* Input Email */}
            <Box sx={classes.containerInputEmail}>
              <TextField
                id='input-email'
                type='email'
                variant='filled'
                size='small'
                label='Correo'
                value={inputEmail}
                onChange={(event) => handleChange('email', event.target.value)}
                InputProps={{
                  endAdornment: <EmailIcon />,
                }}
                error={errorEmail ? false : true}
                sx={classes.inputText}
              />
            </Box>
            {/* Input Password */}
            <Box sx={classes.containerInputPassword}>
              <TextField
                id='input-password'
                type='password'
                variant='filled'
                size='small'
                label='Contraseña'
                value={inputPassword}
                onChange={(event) => handleChange('password', event.target.value)}
                InputProps={{
                  endAdornment: <LockIcon />,
                }}
                error={errorPassword ? false : true}
                sx={classes.inputText}
              />
            </Box>
            {/* Button Action */}
            <Box sx={classes.containerButton}>
              <Button
                type='submit'
                variant='contained'
                sx={classes.Button}
                onClick={handleClick}
              >
                Ingresar
              </Button>
              {isLoading ? <LinearProgress /> : null}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SignInView
