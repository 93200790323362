import React from 'react'

import {Grid, Box, Typography} from '@mui/material'

const classes = {
  root: {
    padding: '16px',
  },
  content: {
    marginTop: '50px',
    textAlign: 'center',
  },
  image: {
    display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '250px',
  },
  contentImg: {
    margin: '24px',
  },
}

const NotFoundView = (props) => {
  return (
    <Box sx={classes.root}>
      <Grid container justifyContent='center' spacing={4}>
        <Grid item lg={6} xs={12}>
          <Box sx={classes.content}>
            <Typography variant='h4'>
              404: The page you are looking for isn’t here
            </Typography>
            <Typography variant='subtitle2'>
              You either tried some shady route or you came here by mistake. Whichever it
              is, try using the navigation
            </Typography>
            <Box sx={classes.contentImg}>
              <img
                alt='Under development'
                sx={classes.image}
                src='/images/not_found.png'
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NotFoundView
