import {AES, enc} from 'crypto-js'
import {datetime} from '../../util/datetime.js'

const encrypt = (data, key) => {
  let dataEncrypted = AES.encrypt(data, key)
  return dataEncrypted.toString()
}
const decrypt = (data, key) => {
  let bytes = AES.decrypt(data, key)
  let dataDecrypted = bytes.toString(enc.Utf8)
  return dataDecrypted
}

const generate = () => {
  let data = datetime()
  return `&!tec/${data.year}tp${data.month}$tp&!/`
}

export const saveDataSession = (data) => {
  try {
    let key = generate()
    let dataEncrypt = encrypt(JSON.stringify(data), key)
    localStorage.removeItem('userSession')
    localStorage.setItem('userSession', dataEncrypt)
    return {save: true}
  } catch (error) {
    return {save: false}
  }
}

export const getDataSession = () => {
  try {
    let key = generate()
    let dataEncrypt = localStorage.getItem('userSession')
    if (dataEncrypt !== null && dataEncrypt !== undefined && dataEncrypt !== '') {
      let dataDecrypt = decrypt(dataEncrypt, key)
      let dataSession = JSON.parse(dataDecrypt)
      if (dataSession.session !== null && dataSession.session !== undefined) {
        if (dataSession.session.state) {
          return {get: true, data: dataSession}
        } else {
          return {get: false}
        }
      } else {
        return {get: false}
      }
    } else {
      return {get: false}
    }
  } catch (error) {
    return {get: false}
  }
}

export const closeSession = () => {
  localStorage.removeItem('userSession')
  return {close: true}
}
