import {zonedTimeToUtc, format} from 'date-fns-tz'
import {sub} from 'date-fns'

export const datetime = () => {
  let dateValue = new Date()
  let utcDate = zonedTimeToUtc(dateValue, 'America/Santiago')

  let HHmm = format(utcDate, 'HH:mm')
  let ddMMyyyy = format(utcDate, 'dd-MM-yyyy')
  let day = format(utcDate, 'dd')
  let month = format(utcDate, 'MM')
  let year = format(utcDate, 'yyyy')
  let now = format(utcDate, 'yyyy-MM-dd HH:mm:ss')

  return {
    now: now,
    nowUTC: utcDate,
    HHmm: HHmm,
    ddMMyyyy: ddMMyyyy,
    day: day,
    month: month,
    year: year,
  }
}

export const rangeDatetime = (start, end) => {
  let dateValue = new Date()
  let utcDate = zonedTimeToUtc(dateValue, 'America/Santiago')

  let todayStart = format(utcDate, 'yyyy-MM-dd 00:00:00')
  let todayEnd = format(utcDate, 'yyyy-MM-dd 23:59:59')

  let lastWeekStart = format(
    sub(new Date(utcDate), {
      weeks: 1,
    }),
    'yyyy-MM-dd 00:00:00'
  )

  let rangeStart = format(start, 'yyyy-MM-dd 00:00:00')
  let rangeEnd = format(end, 'yyyy-MM-dd 23:59:59')

  return {
    today: {start: todayStart, end: todayEnd},
    lastWeek: {start: lastWeekStart, end: todayEnd},
    range: {start: rangeStart, end: rangeEnd},
  }
}

export const formatDatetime = (datetime) => {
  let utcDate = zonedTimeToUtc(datetime, 'America/Santiago')

  return {
    datetime: format(utcDate, 'yyyy-MM-dd HH:mm:ss'),
  }
}

export const formatDatetimeShort = (datetime) => {
  let utcDate = zonedTimeToUtc(datetime, 'America/Santiago')

  return {
    datetime: format(utcDate, 'dd-MM-yyyy HH:mm'),
  }
}
