import {createTheme} from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    dedicated: {
      c1: '#902925',
      c2: '#d82216',
      c3: '#ea5722',
      c4: '#f8a913',
      c5: '#ffcc03',
      c6: '#bfa43c',
      c7: '#bdc121',
      c8: '#95c11f',
      c9: '#4c9c2e',
      c10: '#3a7630',
      c11: '#a5d0f1',
      c12: '#419dd6',
      c13: '#226fb7',
      c14: '#000000',
      c15: '#ffffff',
      c16: '#F4F4F4',
    },
  },
})

export default theme
